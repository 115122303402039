import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login-help',
  templateUrl: './login-help.component.html',
  styleUrls: ['./login-help.component.css']
})
export class LoginHelpComponent implements OnInit {

  emailExists: boolean = false;
  token: string = null;
  validToken: string = "";

  constructor(public user: UserService, private router: Router) { }

  ngOnInit() {
  }

  submitEmail(email: string)
  {
    this.user.notifications = "";

    //send email to backend, check if email exists, if does then send the email reset link
    this.user.sendPasswordResetEmail(email).subscribe((data)=>{
      console.log(data);
      if(data.status == "Success")
      {
        this.emailExists = true;
        console.log("Email Exists!");
      }
      this.user.showAlert(data.status, data.message, (data.status == "Failed" ? true : false));
    });
  }

  submitToken(token)
  {
    this.user.notifications = "";

    this.token = token;

    this.user.checkToken(token).subscribe((data)=>{
      console.log(data);
      if(data.status == "Success")
      {
        this.validToken = data.token;
      }
      this.user.showAlert(data.status, data.message, (data.status == "Failed" ? true : false));
    });
  }

  resetPassword(password, confirmPassword)
  {
    this.user.notifications = "";

    if(password != confirmPassword)
    {
      this.user.showAlert("Failed", "Make sure your passwords match.", true);
      return;
    }

    if(password.length < 8)
    {
      this.user.showAlert("Failed", "Make sure your password is at least 8 characters.", true);
      return;
    }
    
    this.user.resetPassword(password, this.validToken).subscribe((data)=>{
      console.log(data);
      if(data.status == "Success")
      {
        setTimeout(()=>{
          this.router.navigateByUrl('/login');
        }, 1000)
      }
      this.user.showAlert(data.status, data.message, (data.status == "Failed" ? true : false));
    });
  }

  goBack()
  {
    this.user.notifications = "";
    this.router.navigateByUrl('/login');
  }

}
