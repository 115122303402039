import { Component, OnInit } from '@angular/core';
import { SchoolService } from 'src/app/services/school.service';
import { Router } from '@angular/router';
import { IdealSchool } from 'src/app/model/school';
import { UserService } from 'src/app/services/user.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-school-list',
  templateUrl: './school-list.component.html',
  styleUrls: ['./school-list.component.css']
})
export class SchoolListComponent implements OnInit {

  isLoading = true;

  constructor(public school: SchoolService, public route: Router, public user: UserService) {
     
    
    if(localStorage.getItem("loggedInDate") !== null)
    {
      if(localStorage.getItem("currentUserSchool") !== null && localStorage.getItem("currentUserSchool") != "") {

        let savedSchool = JSON.parse(localStorage.getItem("currentUserSchool"));

        this.selectSchool(savedSchool);
        // console.log("Saved School: "+JSON.stringify(savedSchool));
      }
    }
  }
    

  ngOnInit() {
    // let url = window.location.href;
    // console.log(url);
    // if (url.includes('localhost')) {
    //   console.log('Setting to dev environment.')
    //   this.main.isDevelopment = true;
    // }

    //this.school.getSchoolById(19);
    //console.log(this.user.currentUser);
    // this.school.currentUserSchool = null;
    this.getSchoolList();
  }

  getSchoolList() {
    this.isLoading = true;
    this.school.loadedSchools = [];
    // console.log(this.user.currentUser);

    this.school.getSchoolsByUser(this.user.currentUser.id).subscribe(data => {
      // console.log(data);
      this.school.loadedSchools = data.schools;
      this.isLoading = false;
    })
  }

  addSchool() {
    this.school.editSchool(null);
  }

  selectSchool(school: IdealSchool) {
    localStorage.setItem("currentUserSchool", JSON.stringify(school));
    this.school.selectSchool(school);
  }
}
