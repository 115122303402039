import { Component, OnInit } from '@angular/core';
import { SchoolService } from 'src/app/services/school.service';
import { SchoolUtil } from 'src/app/model/school';
import { Person } from 'src/app/model/Person';
import { validateConfig } from '@angular/router/src/config';

@Component({
  selector: 'app-manage-class',
  templateUrl: './manage-class.component.html',
  styleUrls: ['./manage-class.component.css']
})
export class ManageClassComponent implements OnInit {

  public selectedInstructor = 0;
  // public classroom_id = 0;
  public classroom_id : any;
  public course_id = 0;
  public class_id : number;

  public validator: CourseValidator = new CourseValidator();

  constructor(public school: SchoolService) {

    this.school.loadAllCourses();
    this.course_id = this.school.selectedClass.course_id;
    this.classroom_id = this.school.selectedClass.classroom_id;
    this.class_id = this.school.selectedClass.id;

    console.log("class id: "+this.class_id);
   }

  ngOnInit() {
    //console.log(this.school.selectedClass);
    if (this.school.selectedClass.instructor_id) { 
      this.selectedInstructor = this.school.selectedClass.instructor_id;
    }
    if (this.school.selectedClass.classroom_id) {
      this.classroom_id = Number(this.school.selectedClass.classroom_id);
    }
  }

  test()
  {
    this.course_id = 10;
    this.classroom_id = 0;
  }

  cancelUpdate() {
    this.school.viewManageClassPopup = false;
  }

  createMockClass() {
    this.school.selectedClass = new SchoolUtil().createMockClass();
  }

  selectRoom() {
    this.school.selectedClass.classroom_id = this.classroom_id;
    console.log("Selected room: "+this.classroom_id);
  }

  selectCourse() {
    console.log("[ManageClassComponent] Pre selectCourse");
    this.school.selectedClass.course_id = this.course_id;
    console.log("[ManageClassComponent] Post selectCourse");
  }

  selectInstructor() {
    this.school.selectedClass.instructor_id = this.selectedInstructor;
    console.log(this.selectedInstructor);
  }

  saveClass() {
    
    if (this.validator.isValid()) {
      this.school.saveSelectedClass();
    }
  }

  removeClass()
  {
    this.school.deleteClass(this.class_id);
    this.school.viewManageClassPopup = false;
  }
}

export class CourseValidator {
  name_required = false;
  startDate_required = false;
  instructor_required = false;
  classroom_required = false;

  isValid(): boolean {
    let valid = false;

    if (!this.name_required &&
      !this.startDate_required &&
      !this.classroom_required &&
      !this.instructor_required) {
        valid = true;
      }

    return valid;
  }
}
