import { Component, HostListener, OnInit } from '@angular/core';
import { UserService } from './services/user.service';
import { MainService } from './services/main.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ideal-industries';

  constructor(public user: UserService, public main: MainService) {
    // Same as the onResize event below, only it checks the initial state of the window
    if (window.innerWidth < 750) {
      this.main.isCollapsed = true;
    }
  }

  ngOnInit()
  {
    console.log("Last Logged In: "+localStorage.getItem("loggedInDate"));
  }
 
  // Checks the current window width when resizing. If less than 750, collapse the side menu to save space.
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // console.log(event.target.innerWidth);
    if (event.target.innerWidth < 750) {
      this.main.isCollapsed = true;
    }
  }
}
