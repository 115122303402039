import { Component, OnInit } from '@angular/core';
import { SchoolService } from 'src/app/services/school.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import * as lessonModel from 'src/app/model/lesson';

@Component({
  selector: 'app-school-dashboard',
  templateUrl: './school-dashboard.component.html',
  styleUrls: ['./school-dashboard.component.css']
})
export class SchoolDashboardComponent implements OnInit {

  constructor(public school: SchoolService, public user: UserService, public router: Router) { }

  columnDefs = [
    {headerName: 'Student Name', field: 'studentName', sortable: true, filter: true, resizable: true, width: 150  },
    {headerName: 'Student Id', field: 'studentId', sortable: true, filter: true, resizable: true, width: 100 },
    {headerName: 'Type', field: 'test_type', sortable: true, filter: true, resizable: true, width: 80 },
    //{headerName: 'Class Id', field: 'classId', sortable: true, filter: true, resizable: true, width: 50 },
    //{headerName: 'Lesson Id', field: 'lessonId', sortable: true, filter: true, resizable: true, width: 50 },
    {headerName: 'Lesson', field: 'lessonName', sortable: true, filter: true, resizable: true, width: 100 },
    {headerName: 'Score', field: 'score', sortable: true, filter: true, resizable: true, width: 80  },
    {headerName: 'Violations', field: 'violations', sortable: true, filter: true, resizable: true, width: 100  },
    {headerName: 'Elapsed Time', field: 'elapsedTime', sortable: true, filter: true, resizable: true , width: 110 },
    {headerName: 'Date', field: 'date', id:'date', sortable: true, filter: true, resizable: true, width: 200 },
    {headerName: 'SchoolID', field: 'school_id', sortable: true, filter: true, resizable: true },
  ];

  public rowData: any;
  private gridApi;
  private gridColumnApi;

  // userTests: lessonModel.IdealQuiz[] = [];

  public seats;

  ngOnInit() {
    //console.log(this.school.currentUserSchool);
    if (!this.school.currentUserSchool) {
      this.router.navigateByUrl('/school-list');
    }

    // if(localStorage.getItem("currentURL") !== null && localStorage.getItem("currentURL") != "") {
    //   console.log("CURRENT URL: "+localStorage.getItem("currentURL"))
    //   this.router.navigateByUrl(localStorage.getItem("currentURL"));
    // }

    // this.school.loadMockQuizzesForSchool();
    // console.log(this.school.mockQuizzes_School);
    // this.school.getAllQuizzes();
    // console.log(this.school.allQuizzes);
    this.loadQuizzes();
    //this.rowData = this.school.mockQuizzes_School;
    //this.rowData = this.school.allQuizzes;

    this.seats = 
    {
      admin: 0,
      teacher: 0,
      student: 0
    };

    // TODO: find a way to limit the update to when the values are different
    this.updateSeatsCount(this.school.currentUserSchool.id);

  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();

    var sort = [
      {
        colId: "date",
        sort: "desc"
      }
    ];
  
    this.gridApi.setSortModel(sort);
  }




  loadQuizzes() {
    this.school.getAllQuizzes(this.school.currentUserSchool.id).subscribe(data => {
      //console.log(JSON.stringify(data));  // Return the JSON object as a string, so it can be parsed online and mapped to an object
      //console.log(JSON.parse(JSON.stringify(data)));
      //console.log(data.courses[1]);
      this.school.allQuizzes = [];
      this.school.allQuizzesFlat = [];

      // skip if there are no tests yet in this school
      if (!data || !data.courses || data.status == "Failed")
      {
        this.rowData = this.school.allQuizzes;
        return;
      }

      // for (let q of this.school.allQuizzes) {
      //   // console.log(q);
      //   if (q.studentId == this.school.viewUser.id) {
      //     this.userTests.push(q);
      //   }
      // }

      for (let c of data.courses) {
        //console.log(c);
        if (c) {
          for (let l of c.lessons) {
            //console.log(l)
            if (l) {
              for (let t of l.test_attempts) {
                //console.log(t);
                if (t) {
                  //if student, only display the students tests
                  // console.log('RoleID:'+this.user.currentUser.role.id);
                  if (this.user.currentUser.role.id == 4 && t.student_id != this.user.currentUser.id) 
                    continue;

                    // console.log(t);

                  let q = new lessonModel.IdealQuizFlat();
                  q.course_id = c.course_id;
                  q.class_id = t.class_id;
                  q.lesson_id = l.lesson_id;
                  q.test_id = t.test_id;
                  q.test_type =  lessonModel.LessonTypes[t.test_type],
                  q.student_id = t.student_id;
                  q.student_name = this.school.removeDomain(t.student_name);
                  q.start_time = new Date(t.start_time * 1000);
                  q.end_time = new Date(t.end_time * 1000);
                  q.elapsed_time = this.school.getElapsedTimeInMinutes(t.elapsed_time);
                  q.score = t.score;
                  q.violations = t.violations; 
                  q.school_id = t.school_id; 
                  q.lessonName = l.lesson_name;

                  this.school.allQuizzesFlat.push(q);
                  this.school.allQuizzes.push(q.convertToIdealQuiz());
                }
              }
            }
          }
        }
      }

      this.rowData = this.school.allQuizzes;
    });
  }

  updateSeatsCount(id: number)
  {
    this.school.getSchoolActiveSeats(id).subscribe((data) => {
      
      if (!data || !data.seats)
        return;

      data.seats.forEach(role => {
        
        switch (role.role) {
          case 2:
            this.seats.admin = role.seats;
            break;
          case 3:
            this.seats.teacher = role.seats;
            break;
          case 4:
            this.seats.student = role.seats;
            break;
        
          default:
            break;
        }

      });

    });

  }
}