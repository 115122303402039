import * as faker from 'faker';
import { Person } from './Person';

export const LessonTypes = ["N/A", "G", "T"];

export class IdealLesson {
    lesson_id: number;
    sequence_number: number;
    title: string;
    description: string;
    course_id: number;
    picture_url: string;
}

export class IdealQuiz {
    studentId: number;
    studentName: string;
    lessonId: number;
    lessonName: string;
    classId: number;
    testId: string;
    test_type: string;
    date: string;
    startTime: Date;
    endTime: Date;
    elapsedTime: string;
    score: number;
    violations: number;
    school_id: number;
}

export class IdealQuizFlat {
    course_id: number;
    lesson_id: number;
    class_id: number;
    test_id: string;
    test_type: string;
    student_id: number;
    student_name: string;
    lessonName: string;
    start_time: Date;
    end_time: Date;
    elapsed_time: string;
    score: number;
    violations: number;
    school_id: number;

    public convertToIdealQuiz(): IdealQuiz {
        let q: IdealQuiz = new IdealQuiz();

        q.date = this.start_time.toLocaleDateString() + " at "+this.start_time.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
        q.testId = this.test_id;
        q.studentId = this.student_id;
        q.studentName = this.student_name;
        q.lessonId = this.lesson_id;
        q.classId = this.class_id;
        q.startTime = this.start_time;
        q.endTime = this.end_time;
        q.elapsedTime = this.elapsed_time;
        q.score = this.score;
        q.violations = this.violations;
        q.school_id = this.school_id;
        q.lessonName = this.lessonName;
        q.test_type = this.test_type;

        return q;
    }
}


export class LessonUtil {

    createMockLessons(course_id: number) : IdealLesson[] {
        let lessons: IdealLesson[] = [];

        let l = new IdealLesson();
        l.course_id = course_id;
        l.description = faker.lorem.paragraph();
        l.title = "Intro to VR";
        l.sequence_number = 1;
        lessons.push(l);

        for (let i = 1;  i <= 6; i++) {
            let l: IdealLesson = new IdealLesson();
            l.course_id = course_id;
            l.description = faker.lorem.paragraph();
            l.title = 'Lesson ' + i;
            l.sequence_number = i + 1;
            lessons.push(l);
        }

        return lessons;
    }

    createMockQuizzes(student: Person, lessonId: number) : IdealQuiz[] {
        let quizzes: IdealQuiz[] = [];

        let num: number = faker.random.number(7);

        for (let i = 1; i <= num; i++) {
            let q: IdealQuiz = new IdealQuiz();
            //q.id = i.toString();
            q.lessonId = lessonId;
            q.studentId = student.id;
            q.studentName = student.lastname + ", " + student.firstname;
            q.score = faker.random.number(100);
            q.violations = faker.random.number(2);
            q.date = faker.date.past(1).toLocaleString();
            // q.startTime = Date.parse(q.date);
            //q.endTime = new Date(q.date.getTime() + faker.random.number(45)*60000).toLocaleTimeString();
            //q.elapsedTime = new Date(q.endTime).valueOf() - new Date(q.startTime).valueOf();
            q.elapsedTime = faker.random.number(45).toString();
            
            quizzes.push(q);
        }

        return quizzes;
    }

}