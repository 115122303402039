import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';

 /** A router wrapper, adding extra functions. */
@Injectable()
export class RouterHistoryService {
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  //These urls won't be saved in the history for now, support needs added for them. This is not the preferred solution. A better solution is needed for the refresh issue.
  private avoid:string[] = [
    "/",
    "/login",
    "/school-list",
    "/view-user",
    "/view-class",
    "/account"
  ];

  constructor(private router : Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {   

        if(event.url == "/school-dashboard")
        {
          let clear = localStorage.getItem("clearURL");
          let savedURL = localStorage.getItem("currentURL");

          if(savedURL === null)
            return;
            
          if(savedURL != event.url && savedURL != "" && clear == "false")
          {
            this.router.navigateByUrl(savedURL);
          }
        }     

        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;

        if(!this.avoid.includes(this.currentUrl))
        {
          localStorage.setItem("currentURL", this.currentUrl);
          // console.log("SAVED URL: "+this.currentUrl);
        }
      };
    });
  }

  public getPreviousUrl(){
    return this.previousUrl;
  }
}
