import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/account/login/login.component';
import { AdminDashboardComponent } from './components/admin/admin-dashboard/admin-dashboard.component';
import { SuperUserDashboardComponent } from './components/super-user/super-user-dashboard/super-user-dashboard.component';
import { TeacherDashboardComponent } from './components/student/teacher-dashboard/teacher-dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavBarComponent } from './components/core/nav-bar/nav-bar.component';
import { DashboardComponent } from './components/core/dashboard/dashboard.component';
import { UserAccountComponent } from './components/account/user-account/user-account.component';
import { LogoutComponent } from './components/account/logout/logout.component';
import { ManageUserComponent } from './components/core/manage-user/manage-user.component';
import { ManageAccountsComponent } from './components/core/manage-accounts/manage-accounts.component';
import { SideMenuComponent } from './components/core/side-menu/side-menu.component';
import { SchoolListComponent } from './components/core/school-list/school-list.component';
import { ClassOverviewComponent } from './components/core/class-overview/class-overview.component';
import { ManageSchoolComponent } from './components/core/manage-school/manage-school.component';
import { ManageClassComponent } from './components/core/manage-class/manage-class.component';
import { ClassListComponent } from './components/core/class-list/class-list.component';
import { ViewClassComponent } from './components/core/view-class/view-class.component';
import { ViewUserComponent } from './components/core/view-user/view-user.component';
import { AddStudentsToClassComponent } from './components/core/add-students-to-class/add-students-to-class.component';
import { HelpPageComponent } from './components/core/help-page/help-page.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { SchoolDashboardComponent } from './components/core/school-dashboard/school-dashboard.component';

import { AgGridModule } from 'ag-grid-angular';
import { ManageLessonComponent } from './components/core/manage-lesson/manage-lesson.component';
import { ManageRoomsComponent } from './components/core/manage-rooms/manage-rooms.component';
import { LoginHelpComponent } from './components/core/login-help/login-help.component';
import { ViewTestSummaryComponent } from './components/core/view-test-summary/view-test-summary.component';
import { ManageCoursesComponent } from './components/core/manage-courses/manage-courses.component';
import { RouterHistoryService } from './services/router-history.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminDashboardComponent,
    SuperUserDashboardComponent,
    TeacherDashboardComponent,
    NavBarComponent,
    DashboardComponent,
    UserAccountComponent,
    LogoutComponent,
    ManageUserComponent,
    ManageAccountsComponent,
    SideMenuComponent,
    SchoolListComponent,
    ClassOverviewComponent,
    ManageSchoolComponent,
    ManageClassComponent,
    ClassListComponent,
    ViewClassComponent,
    ViewUserComponent,
    AddStudentsToClassComponent,
    HelpPageComponent,
    SchoolDashboardComponent,
    ManageLessonComponent,
    ManageRoomsComponent,
    LoginHelpComponent,
    ViewTestSummaryComponent,
    ManageCoursesComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFontAwesomeModule,
    AgGridModule.withComponents([])
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private routerHistory: RouterHistoryService)
  { }
}
