import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { SchoolService } from 'src/app/services/school.service';
import { Person } from 'src/app/model/Person';
import { Router } from '@angular/router';
import * as lessonModel from 'src/app/model/lesson';

@Component({
  selector: 'app-manage-accounts',
  templateUrl: './manage-accounts.component.html',
  styleUrls: ['./manage-accounts.component.css']
})
export class ManageAccountsComponent implements OnInit {

  viewInactive = false;
  viewActive = true;

  public rowData: any;

  constructor(public user: UserService, public school: SchoolService, public router: Router) {
    this.school.loadedUsers = null;
    this.school.loadedStudents = null;
   }

  ngOnInit() {
    this.school.loadUsersBySchool(this.school.currentUserSchool.id);
    this.loadQuizzes();
  }

  addAdmin() {
    this.school.createSchoolAdmin();
  }

  addInstructor() {
    this.school.createInstructor();
  }

  addStudent() {
    this.school.createStudent();
  }

  viewUser(u: Person) {
    // console.log(JSON.stringify(u));
    this.school.viewUser = u;
    this.router.navigateByUrl('/view-user');
  }

  editUser(u: Person) {
    this.school.selectedUser = u;
    this.school.editUser(u);
  }

  unDeleteUser(u: Person) {
    this.user.unDeleteUser(u.id).subscribe(data => {
      console.log(data);
      this.school.loadUsersBySchool(this.school.currentUserSchool.id);
      this.showActiveUsers();
    })
  }

  showActiveUsers() {
    this.viewActive = true;
    this.viewInactive = false;
  }

  showInactiveUsers() {
    this.viewActive = false;
    this.viewInactive = true;
  }

  confirmRemoveAllStudents()
  {
    if (confirm("Are you sure you want to remove all students? This cannot be undone! This is not a soft removal. Students will be deleted.")) {
      //proceed
      console.log("Removing all students...");
      this.removeAllStudents();
    } else {
      //don't proceed
      console.log("Cancelling...");
    }
  }

  removeAllStudents()
  {
    this.school.removeAllStudents();
  }

  //testing only
  addUserRow()
  {
    let user  = new Person();
    user.firstname = "test";
    user.lastname = "test";
    this.school.addUserToListGroup(user, 2);
  }

  loadQuizzes() {
    this.school.getAllQuizzes(this.school.currentUserSchool.id).subscribe(data => {

      this.school.allQuizzes = [];
      this.school.allQuizzesFlat = [];

      // skip if there are no tests yet in this school
      if (!data || !data.courses || data.status == "Failed")
      {
        this.rowData = this.school.allQuizzes;
        return;
      }

      for (let c of data.courses) {
        //console.log(c);
        if (c) {
          for (let l of c.lessons) {
            //console.log(l)
            if (l) {
              for (let t of l.test_attempts) {
                //console.log(t);
                if (t) {
                  //if student, only display the students tests
                  // console.log('RoleID:'+this.user.currentUser.role.id);
                  if (this.user.currentUser.role.id == 4 && t.student_id != this.user.currentUser.id) 
                    continue;

                    // console.log(t);

                  let q = new lessonModel.IdealQuizFlat();
                  q.course_id = c.course_id;
                  q.class_id = t.class_id;
                  q.lesson_id = l.lesson_id;
                  q.test_id = t.test_id;
                  q.test_type =  lessonModel.LessonTypes[t.test_type],
                  q.student_id = t.student_id;
                  q.student_name = this.school.removeDomain(t.student_name);
                  q.start_time = new Date(t.start_time * 1000);
                  q.end_time = new Date(t.end_time * 1000);
                  q.elapsed_time = this.school.getElapsedTimeInMinutes(t.elapsed_time);
                  q.score = t.score;
                  q.violations = t.violations; 
                  q.school_id = t.school_id; 
                  q.lessonName = l.lesson_name;

                  this.school.allQuizzesFlat.push(q);
                  this.school.allQuizzes.push(q.convertToIdealQuiz());
                }
              }
            }
          }
        }
      }

      this.rowData = this.school.allQuizzes;
    });
  }
}