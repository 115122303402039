import { Component, OnInit } from '@angular/core';
import { SchoolService } from 'src/app/services/school.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { IdealLesson, IdealQuizFlat } from 'src/app/model/lesson';
import { IdealQuiz } from '../../../model/lesson';

@Component({
  selector: 'app-view-class',
  templateUrl: './view-class.component.html',
  styleUrls: ['./view-class.component.css']
})
export class ViewClassComponent implements OnInit {

  userTests: IdealQuiz[] = [];
  bestTest: IdealQuiz;
  latestTest: IdealQuiz;
  timeSpent: number = 0;
  
  constructor(public school: SchoolService, public user: UserService, public router: Router) { }

  columnDefs = [
    {headerName: 'Student Name', field: 'studentName', sortable: true, filter: true, resizable: true, width: 150  },
    //{headerName: 'Student Id', field: 'studentId', sortable: true, filter: true, resizable: true, width: 50 },
    //{headerName: 'Class Id', field: 'classId', sortable: true, filter: true, resizable: true, width: 50 },
    {headerName: 'Lesson Id', field: 'lessonId', sortable: true, filter: true, resizable: true, width: 70 },
    {headerName: 'Lesson', field: 'lessonName', sortable: true, filter: true, resizable: true, width: 70 },
    {headerName: 'Score', field: 'score', sortable: true, filter: true, resizable: true, width: 70  },
    {headerName: 'Violations', field: 'violations', sortable: true, filter: true, resizable: true, width: 70  },
    {headerName: 'Elapsed Time', field: 'elapsedTime', sortable: true, filter: true, resizable: true , width: 90 },
    {headerName: 'Date', field: 'date', sortable: true, filter: true, resizable: true },
];

  public loadedLessonsByClass: any;

  public rowData: any;
  private gridApi;
  private gridColumnApi;

  ngOnInit() {
    console.log('view-class init method...')
    console.log(this.school.selectedClass);

    this.school.getStudentsByClass(this.school.selectedClass.id);

    //this.rowData = this.school.mockQuizzes_School;
    this.loadQuizzes();
    this.loadLessons();
    // this.rowData = this.school.allQuizzes;

    console.log("Loaded lessons length: "+this.school.loadedLessonsByClass.length);
  }

  addStudent() {
    this.school.viewAddStudentToClassPopup = true;
  }

  addLesson() {
    this.school.selectedLesson = new IdealLesson();
    this.school.viewManageLessonPopup = true;
  }

  loadLessons() {
    this.school.getLessonsByClass(this.school.selectedClass.id).subscribe(data => {

      console.log("Loaded Lessons: "+JSON.stringify(data));

      if(data.status == "Success")
      {
        this.loadedLessonsByClass = data.lessons;
      }
      else
        this.loadedLessonsByClass = null;
      //console.log(this.loadedLessonsByClass);
    })
  }

  viewUser(id: number) {
    console.log(id);
    //this.user.loadUserById(id);
    this.school.viewUser = this.school.findUserById(id);
    //console.log(this.viewUser);
    this.school.viewUser = this.school.getLoadedStudent(id);
    this.router.navigateByUrl('view-user');
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  }

  // onSelectionChanged() {
  //   console.log('clicked!');
  //   var selectedRows = this.gridApi.getSelectedRows();
  //   console.log(selectedRows);
  //   // document.querySelector('#selectedRows').innerHTML =
  //   //   selectedRows.length === 1 ? selectedRows[0].athlete : '';
  // }

  onRowClicked(event: any) { 
    console.log('row', event); 
    let q: IdealQuiz = event.data;

    this.school.TestSummaryId = q.testId;
    this.school.TestSummaryStudentId = q.studentId;

    this.school.viewTestSummaryPopup = true;
  }
  // onCellClicked(event: any) { console.log('cell', event); }
  // onSelectionChanged(event: any) { console.log("selection", event); }

  loadQuizzes() {
    let originalQuizes = this.school.allQuizzes;
    this.school.getAllQuizzesByClass(this.school.selectedClass.id).subscribe(data => {
      //console.log(JSON.stringify(data));  // Return the JSON object as a string, so it can be parsed online and mapped to an object
      console.log(JSON.parse(JSON.stringify(data)));
      //console.log(data.class);

      if(data.status == "Failed")
      {
        console.log("No test attempts.");
        this.rowData = [];
        return;
      }

      this.school.allQuizzesFlat = [];
      this.school.allQuizzes = [];

          for (let l of data.class.lessons) {
            //console.log(l)
            if (l) {
              for (let t of l.test_attempts) {
                //console.log(t);
                if (t) {
                  if (this.user.currentUser.role.id == 4 && t.student_id != this.user.currentUser.id) 
                    continue;

                  let q = new IdealQuizFlat();
                  console.log(t);
                  q.course_id = data.class.course_id;
                  q.lesson_id = l.lesson_id;
                  q.lessonName = l.lesson_name;
                  q.test_id = t.test_id;
                  q.student_id = t.student_id;
                  q.student_name = this.school.removeDomain(t.student_name);
                  q.start_time = new Date(t.start_time * 1000);
                  q.end_time = new Date(t.end_time * 1000);
                  q.elapsed_time = this.school.getElapsedTimeInMinutes(t.elapsed_time);
                  q.score = t.score;
                  q.violations = t.violations; 

                  this.school.allQuizzesFlat.push(q);
                  this.school.allQuizzes.push(q.convertToIdealQuiz());
                }
              }
            }
          }
      console.log(this.school.allQuizzes);
      this.rowData = this.school.allQuizzes;
      this.school.allQuizzes = originalQuizes;
    });
  }
}
