import { Component, OnInit } from '@angular/core';
import { SchoolService } from 'src/app/services/school.service';
import { SchoolUtil } from 'src/app/model/school';
import { IdealCourse } from 'src/app/model/class';
import { IdealLesson } from 'src/app/model/lesson';

@Component({
  selector: 'app-manage-courses',
  templateUrl: './manage-courses.component.html',
  styleUrls: ['./manage-courses.component.css']
})

export class ManageCoursesComponent implements OnInit {

  public course_id = 0;
  public course_active: boolean = false;
  public lesson_id : number;

  constructor(public school: SchoolService) {

    this.school.loadAllCourses();
    this.school.selectedLesson = new IdealLesson();
   }

  ngOnInit() {
    // this.school.showAlert("")
  }

  cancelUpdate() {
    this.school.viewManageCoursePopup = false;
  }

  selectCourse() {

    if(this.course_id == 0)
    {
      this.school.selectedCourse = new IdealCourse();
      return;
    }

    //get the index of the course_id from the currenty loaded courses
    let i =this.school.loadedCourses.findIndex(item=>item.id == this.course_id);

    this.school.selectedCourse = this.school.loadedCourses[i];
    this.school.getLessonsByCourse(this.course_id);

    if(this.school.selectedCourse.active == "Y" || this.school.selectedCourse.active == "1")
      this.course_active = true;
    else 
      this.course_active = false;
  }

  selectLesson(lessonID)
  {
    console.log("LessonID: "+lessonID);
    //Get the index of the lesson by the lesson ID
    let i =this.school.loadedLessonsByCourse.findIndex(item=>item.lesson_id == lessonID);

    this.school.selectedLesson = this.school.loadedLessonsByCourse[i];

    console.log("Lesson: "+JSON.stringify(this.school.selectedLesson));
  }

  saveCourse()
  {
    this.school.selectedCourse.active = this.course_active ? "Y":"N";

    if(this.school.selectedCourse.id)
    {
      console.log("Updating course");
      this.updateCourse();
    }
    else
    {
      console.log("Adding new course");
      this.addCourse();
    }
  }

  addCourse() {
    this.school.addSelectedCourse();
  }

  updateCourse()
  {
    this.school.saveSelectedCourse();
  }

  saveLesson()
  {
    if(this.school.selectedLesson.lesson_id)
    {
      console.log("Updating lesson");
      this.updateLesson();
    }
    else
    {
      console.log("Adding new lesson");
      this.addLesson();
    }
  }

  addLesson()
  {
    if(this.school.selectedCourse == null)
    {
      console.log("You must have a course selected first.");
      return;
    }

    this.school.addSelectedLesson();
    this.school.selectedLesson = new IdealLesson();
  }

  updateLesson()
  {
    if(this.school.selectedCourse == null)
    {
      console.log("You must have a course selected first.");
      return;
    }

    this.school.saveSelectedLesson();
  }

  clearCourseSelection()
  {
    this.school.selectedCourse = new IdealCourse();
    this.school.selectedLesson = new IdealLesson();
    this.course_id = 0;
    this.course_active = false;
  }

  clearLessonSelection()
  {
    this.school.selectedLesson = new IdealLesson();
  }

  removeCourse()
  {
    console.log("Deleting course...");
    this.school.deleteCourse(this.school.selectedCourse.id);
    this.course_active = false;
  }

  deleteLesson()
  {
    if(!this.school.selectedCourse && !this.school.selectedLesson)
      return;

    console.log("Deleting lesson...");
    this.school.deleteLesson(this.school.selectedLesson.lesson_id);
  }
}
