export class Role {
    id: number;
    name: string;

    constructor(id: number, name: string) {
        this.id = id;
        this.name = name;
    }
}


export const ROLES: Role[] = [
    { id: 1, name: "Super User" },
    { id: 2, name: "School Admin" },
    { id: 3, name: "Instructor" },
    { id: 4, name: "Student" },
];
