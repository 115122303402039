import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  public isCollapsed = false;
  public isDevelopment = false;

  constructor(public router: Router) {
    
   }

  toggleSideMenu() {
    this.isCollapsed = !this.isCollapsed;
  }
}
