import { Person, PersonUtil } from './Person';
import { IdealClass } from './class';
import * as faker from 'faker';

export class IdealSchool {

    constructor() {
        this.id = 0;
        this.name = '';
        this.address1 = '';
        this.address2 = '';
        this.city = '';
        this.state = '';
        this.zipcode = '';
        this.email = '';
        this.phone = '';
        this.pocid = 0;
        this.total_seats = 0;
        this.active_seats = 0;
        this.admin_seats = 0;
        this.teacher_seats = 0;
        this.student_seats = 0;
	      this.scorm = '0';
        this.domain = '';
        //this.administrator = null;
        //this.classes = [];
    }

    id: number;
    name: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    email: string;
    phone: string;
    pocid: number;

    //administrator: Person;
    classes: IdealClass[] = [];

    total_seats: number;
    active_seats: number;
    admin_seats: number;
    teacher_seats: number;
    student_seats: number;
    scorm: string = '0';
    domain: string = '';

    // this is the same as active seats
    // totalStudents() {
    //     let total = 0;
    //     for (let c of this.classes) {
    //         total += c.students.length;
    //     }
    //     return total;
    // }
}


export class SchoolUtil {
    
  getMockSchool() {
    let s: IdealSchool = new IdealSchool();
    s.email = faker.internet.email();
    s.address1 = faker.address.streetAddress();
    s.address2 = '';
    s.city = faker.address.city();
    s.state = faker.address.state();
    s.zipcode = faker. address.zipCode();
    s.name = faker.name.jobTitle() + " Academy";
    s.phone = faker.phone.phoneNumber();
    //s.id = faker.random.uuid();
    s.pocid = 2;
    //s.total_seats = faker.random.number(50);
    s.admin_seats = faker.random.number(50);
    s.teacher_seats = faker.random.number(50);
    s.student_seats = faker.random.number(50);
    //s.administrator = new PersonUtil().getMockUser(1);
    //s.totalSeats = faker.random.number(50);
    return s;
  }

  createMockClass() {
    let c: IdealClass = new IdealClass();
    
    c.name = "Intro to " + faker.name.jobTitle();
    c.classroom_id = faker.random.number(9999);
    c.instructor_id = faker.random.number(9999);
    //c.id = faker.random.uuid();
    c.start_time = "1:00 PM";  //faker.random.number().toString();
    c.end_time = "1:45 PM";  //faker.random.number().toString();
    c.start_date = "2020-01-15";  //faker.random.number().toString();
    c.end_date = "2020-05-19";  //faker.random.number().toString();
    
    // c.instructor = new PersonUtil().getMockUser(3);
    // c.students = [];
    // let total = faker.random.number(20);
    // for (let i = 0; i < total; i++) {
    //   c.students.push(new PersonUtil().getMockUser(1));
    // }

    console.log(faker.name.jobTitle());
    return c;
  }

}