import { Component, OnInit } from '@angular/core';
import { SchoolService } from 'src/app/services/school.service';
import { Person } from 'src/app/model/Person';

@Component({
  selector: 'app-add-students-to-class',
  templateUrl: './add-students-to-class.component.html',
  styleUrls: ['./add-students-to-class.component.css']
})
export class AddStudentsToClassComponent implements OnInit {

  constructor(public school: SchoolService) { }

  availableStudents: Person[] = [];

  ngOnInit() {
    this.availableStudents = Object.assign([], this.school.loadedStudents);
    if (this.school.loadedStudentsByClass) {
      for (let s of this.school.loadedStudentsByClass) {
        for (let a of this.availableStudents) {
          if (s.id == a.id) {
            let index = this.availableStudents.indexOf(a);
            this.availableStudents.splice(index, 1);
            console.log(this.availableStudents);
          }
        }

      }
    }
    console.log("Available: "+JSON.stringify(this.availableStudents));
  }

  cancelAdd() {
    this.school.viewAddStudentToClassPopup = false;
  }

  enrollStudent(student: Person) {
    this.school.enrollStudent(student.id, this.school.selectedClass.id);
    this.availableStudents.splice(this.availableStudents.indexOf(student), 1);
  }

  unenrollStudent(student: Person) {
    this.school.unenrollStudent(student.id, this.school.selectedClass.id);

    let i = this.school.loadedStudents.findIndex(item=>item.id == student.id);
    this.availableStudents.push(this.school.loadedStudents[i]);

  }
}
 