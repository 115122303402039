import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { SchoolService } from 'src/app/services/school.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {

  constructor(public user: UserService, public router: Router, public school: SchoolService, public main: MainService) { }

  public showSchoolPopup : boolean = false;

  ngOnInit() {
    //this.user.testRead();
  }

  showManageAccounts() {
    this.router.navigateByUrl('/manage-accounts');
    this.closeViews();
  }

  showClassList() {
    this.router.navigateByUrl('/class-list');
    this.closeViews();
  }

  showHelpPage() {
    this.router.navigateByUrl('/help-page');
    this.closeViews();
  }

  showQuickGuide() {
    this.router.navigateByUrl('/quick_guide');
    this.closeViews();
  }

  showSchoolSettings()
  {
    if(this.school.viewManageSchoolPopup)
    {
      this.showSchoolPopup = false;
      this.school.doSchoolUpdate = false;
      this.school.doSchoolCreate = false;
      this.school.viewManageSchoolPopup = false;
    }
    else
    {
      this.showSchoolPopup = true;
      this.school.doSchoolUpdate = true;
      this.school.doSchoolCreate = false;
    }
  }

  showSchoolList() {
    localStorage.setItem("currentUserSchool", "");
    localStorage.setItem("currentURL", "");
    this.router.navigateByUrl('/school-list');
    this.closeViews();
  }
  
  showDashboard() {
    // if(localStorage.getItem("currentUserSchool") === null || localStorage.getItem("currentUserSchool") == "")
    //   return;
      
    this.router.navigateByUrl('/school-dashboard');
    this.closeViews();
  }

  showUserClasses() {
    // if(localStorage.getItem("currentUserSchool") === null || localStorage.getItem("currentUserSchool") == "")
    //   return;

    this.school.viewUser = this.user.currentUser;
    this.router.navigateByUrl('/view-user');
    this.closeViews();
  }

  doDateConversion() {
    let d = new Date(1579564479000);
    console.log(d);
    console.log(d.getHours());
    console.log(d.getMinutes());
  }

  openGuide()
  {
    // this.router.navigateByUrl('https://www.fnfsandbox.com/ideal/QUICK_GUIDE.pdf');
    window.open('https://www.fnfsandbox.com/ideal/QUICK_GUIDE.pdf', '_blank');
  }

  hover(state: boolean)
  {
    if(state)
    {
      localStorage.setItem("clearURL", "true");
    }
    else
    {
      localStorage.setItem("clearURL", "false");
    }
  }

  closeViews()
  {
    
    this.school.viewManageAccountPopup = false;
    this.school.viewManageClassPopup = false;
    this.school.viewManageCoursePopup = false;
    this.school.viewManageLessonPopup = false;
    this.school.viewManageRoomsPopup = false;
    this.school.viewManageSchoolPopup = this.showSchoolPopup;
    this.showSchoolPopup = false;
    this.school.doSchoolUpdate = false;
  }

}
