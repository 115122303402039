import { Component, OnInit } from '@angular/core';
import { SchoolService } from 'src/app/services/school.service';
import { IdealClass } from 'src/app/model/class';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-class-list',
  templateUrl: './class-list.component.html',
  styleUrls: ['./class-list.component.css']
})
export class ClassListComponent implements OnInit {

	viewCourses : boolean = false;

  constructor(public school: SchoolService, public router: Router, public user: UserService) { }

  ngOnInit() {
    this.school.getClassesBySchoolId(this.school.currentUserSchool.id);
    this.school.loadUsersBySchool(this.school.currentUserSchool.id);
  }

  toggleViewCourses()
  {
    this.viewCourses = !this.viewCourses;
    console.log("Toggled: "+ this.viewCourses);
  }

  addRooms() {
    this.school.viewManageRoomsPopup = true;
  }

  addClass() {
    this.school.editClass(null);
  }

  addCourse() {
    this.school.editCourse(null);
  }

  viewClass(cls: IdealClass) {
    this.school.selectedClass = cls;
    console.log(this.school.selectedClass);
    this.router.navigateByUrl('/view-class');
  }
}
