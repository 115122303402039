import { Person } from './Person';

export class IdealClass {

    constructor() {
        this.name = "";
        this.course_name = "";
    }

    id: number;
    name: string;
    course_name : string;
    description: string;
    enrolled: number;
    instructor: Person;
    instructor_id: number;
    classroom_id: number;
    room: string;
    days: string;
    start_date: string;
    end_date: string;
    start_time: string;
    end_time: string;
    monday = 1;
    tuesday = 0;
    wednesday = 1;
    thursday = 0;
    friday = 1;
    saturday = 0;
    sunday = 0;
    school_id: number;
    course_id = 0;
    active : string;

    //students: Person[] = [];
    
}

export class IdealClassroom {
    id: number;
    room_number: string;
    school_id: number;
    seats: number;
}

export class IdealCourse {

//     constructor(cls: IdealClass) {
//         if (cls.id) {
//             this.id = cls.id;
//         }
//         this.name = cls.course_name;
//         this.desc = cls.description;
//     }
    id: number;
    name: string;
    description: string;
    active: string;
}