import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { SchoolService } from 'src/app/services/school.service';
import { PersonUtil, Person } from 'src/app/model/Person';
import { IdealSchool } from 'src/app/model/school';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.css']
})
export class ManageUserComponent implements OnInit {

  selectedSchool: IdealSchool;
  createNew = true;
  addExisting = false;
  errorMessage = '';

  constructor(public user: UserService, public school: SchoolService, public router: Router) { }

  ngOnInit() {
  }

  cancelUpdate() {
    this.school.viewManageAccountPopup = false;
  }

  updateAccount() 
  {
    if (this.school.doUserCreate) 
    {
      let roleId = this.school.manageAccountUserRole.id;
      let schoolId = this.school.currentUserSchool.id;

      this.school.addSelectedUser(roleId, schoolId).subscribe(data => 
      {
        console.log(data);
        this.school.showAlert(data.status, data.message, (data.status == "Failed" ? true : false));
        if (data.status == 'Failed') {
          // Show error message
          this.errorMessage = data.message;
          return;
        }

        // console.log("Data:"+JSON.stringify(data));

        // this.school.selectedUser = data.person;
        this.school.selectedUser = new Person();
        this.school.selectedUser.id = data.person.p_id;
        this.school.selectedUser.role = this.school.setUserRole(roleId);
        this.school.selectedUser.username = data.person.UserName;
        this.school.selectedUser.firstname = data.person.FirstName;
        this.school.selectedUser.lastname = data.person.LastName;
        this.school.selectedUser.email = data.person.Email;
        this.school.selectedUser.phone1 = data.person.Phone1;
        this.school.selectedUser.phone2 = data.person.Phone2;
        this.school.selectedUser.isSuperUser = false;

        this.school.updateUserRoleAndSchool(this.school.selectedUser.id, roleId, schoolId).subscribe((data) => 
        {
          console.log(data);
        });

        // console.log("Selected User:"+JSON.stringify(this.school.selectedUser));

        this.school.addUserToListGroup(this.school.selectedUser, roleId);
        this.school.viewManageAccountPopup = false;
      });
    }
    else if (this.school.doUserUpdate) 
    {
      this.school.updateSelectedUser().subscribe(data => 
      {
        console.log(data);
        this.school.viewManageAccountPopup = false;
      });
    }
  }

  createMockUser() {
    this.school.selectedUser = new PersonUtil().getMockUser(3);
  }

  switchToNew() {
    this.createNew = true;
    this.addExisting = false;
  }

  switchToExisting() {
    this.createNew = false;
    this.addExisting = true;
    this.school.loadAllUsers();
  }

  selectUser(person: Person) {
    this.school.selectedUser = person;
    
    // Can't do this long term. Need to create a new UI for confirming that a selected user is being added to a school.
    
    this.switchToNew();
  }

  deleteUser() {
    if (confirm('Are you sure you want to delete this user? They will be marked inactive, and can be restored from the Inactive Users list.')) {
      this.user.deleteUser(this.school.selectedUser.id).subscribe(data => {
        console.log(data);
        this.school.loadUsersBySchool(this.school.currentUserSchool.id);
        this.school.viewManageAccountPopup = false;  
      });
    }
  }
}
