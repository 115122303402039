import { Injectable } from '@angular/core';
import { Person, PersonUtil } from '../model/Person';
import { RouterModule, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CanActivate } from '@angular/router/src/utils/preactivation';
import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Role, ROLES } from '../model/role';
import { jsonpCallbackContext } from '@angular/common/http/src/module';
import * as jwt from 'jsonwebtoken';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public isLoggedIn = false;
  public currentUser: Person;

  public onLoginError = false;
  public loginErrorText = '';

  public token = '';

  public notifications: string = "";

  //viewUser: Person;
  
  devURL = '/api/';
  liveURL = 'https://api.ivrylearning.com/';
  baseUrl = 'https://api.ivrylearning.com/';
  // baseUrl = '/api/';
  //baseUrl = 'https://www.fnfsandbox.com/ideal-api/';

  constructor(public http: HttpClient, public router: Router) {

    let url = window.location.href;
    console.log(url);
    if (url.includes('localhost')) {
      this.baseUrl = this.devURL;
    }
    else
    {
      this.baseUrl = this.liveURL;
    }

    if (localStorage.getItem('currentUser')) {
      // TODO: Need to also check how long since the last log in date.
      let date = localStorage.getItem('loggedInDate');

      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.doLogin(this.currentUser.id, '', true);
    }

    if (!this.isLoggedIn) {
      console.log("NOT LOGGED IN");
      this.router.navigateByUrl('/login');
    }
   }

  login(username: string, password: string, keepLoggedIn: boolean) {

    this.notifications = "";
    this.onLoginError = false;
    
    //console.log(keepLoggedIn);
    //let body = this.encodeRequestBody(username, password);
    let body = { 'username': username, 'password': password }

    this.http.post(this.baseUrl + 'person/getToken.php', body, { headers: new HttpHeaders({'Content-Type': 'application/json'}), responseType: 'text'}).subscribe(response => {
      // console.log(response);

      let data = JSON.parse(response);

      if (data.status == "Success") {
        this.notifications = "";

        this.doLogin(data.id, data.token, keepLoggedIn);
      }
      else if (data.status == "Failed") {
        //TODO: Show an error
        this.showAlert(data.status, data.message, (data.status == "Failed" ? true : false));
      }
    })
  }

  encodeRequestBody(username: string, password: string) {
    let token1 = btoa(JSON.stringify({ 'alg': 'HS256', 'typ': 'JWT' }));
    let token2 = btoa(JSON.stringify({ 'username': username, 'password': password }));
    let tokens = token1 + "." + token2;

    return { tokens };
  }

  testLogin(roleId: number) {
    this.currentUser = new PersonUtil().getMockUser(roleId);
    this.isLoggedIn = true;
    this.router.navigateByUrl('/school-list');
  }

  testJWTLogin() {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

    let body = { 
      "username": "test30",
      "password": "pass1"
    }

    this.http.post(this.baseUrl + 'person/login.php', body, { headers: new HttpHeaders({'Content-Type': 'application/json'}), responseType: 'text'}).subscribe(data => {
      console.log(data);
      //console.log(atob(data.split('.')[1]));
      //this.viewUser = data.people[0]; 
      //console.log(this.viewUser);
    })
  }

  logout() {
    this.currentUser = null;
    this.isLoggedIn = false;
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }

  userHasAccess(roleId: number): boolean {

    if(this.currentUser.isSuperUser)
      return true;

    if(this.currentUser.role === undefined)
      return false;

    if (this.currentUser.role.id <= roleId || this.currentUser.isSuperUser) {
      return true;
    }
    return false;
  }

  /// Get the user by id, set them as the current user, and mark them as logged in.
  doLogin(id: number, token: string, keepLoggedIn: boolean) {
    this.getUserById(id).subscribe(data => {
      // console.log(data);
      this.token = token;
      this.currentUser = data.people[0];
      this.isLoggedIn = true;
      this.isSuperUser();
      
      this.keepUserLoggedIn();
      // if (keepLoggedIn) {
      //   this.keepUserLoggedIn();
      // }

      this.router.navigateByUrl('/school-list');    
    })
  }

  getUserById(id: number) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    let body = { 
      "searchby": "id",
      "id": id,
      "email": ""
    }

    return this.http.post<any>(this.baseUrl + 'person/inq.php', body, options);
  }

  isSuperUser() {
    // Hard coding the super user logins for now
    if (this.currentUser.username == 'kriskramer' || this.currentUser.username == 'grok241' || this.currentUser.username == 'ivrysam') {
      this.currentUser.isSuperUser = true;
    }
  }

  keepUserLoggedIn() {
    // Save the currentUser object to local data. Check on next page load to bypass login if it exists (need to set a time limit)
    // console.log("Saving user data for keepLoggedIn..."+JSON.stringify(this.currentUser));
    localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
    localStorage.setItem('loggedInDate', Date.now().toString());
  }

  // For updating the logged in user via the User's account page (link in the nav bar)
  updateCurrentUser() {
    let header = new HttpHeaders({'content-type': 'application/json'});

    return this.http.post<any>(this.baseUrl + 'person/upd.php', this.currentUser, {headers : header})
  }

  sendPasswordResetEmail(email: string) {
    let header = new HttpHeaders({'content-type': 'application/json'});

    let body = {
      "email": email
    };

    return this.http.post<any>(this.baseUrl + 'person/forgotPassword.php', body, {headers : header});
  }

  checkToken(token: string){
    let header = new HttpHeaders({'content-type': 'application/json'});

    let body = {
      "token": token
    };

    return this.http.post<any>(this.baseUrl + 'person/auth.php', body, {headers : header});
  }

  resetPassword(password: string, token: string){
    let header = new HttpHeaders({'content-type': 'application/json'});

    let body = {
      "password": password,
      "token": token
    };

    return this.http.post<any>(this.baseUrl + 'person/resetPassword.php', body, {headers : header});
  }

  updatePassword(currentPassword: string, newPassword: string){
    let header = new HttpHeaders({'content-type': 'application/json'});

    let body = {
      "email": this.currentUser.email,
      "current_password": currentPassword,
      "password": newPassword
    };

    console.log("Sending: "+ JSON.stringify(body));

    return this.http.post<any>(this.baseUrl + 'person/resetPassword.php', body, {headers : header});
  }

  sendConfirmationEmail() {
    let header = new HttpHeaders({'content-type': 'application/json'});

    let body = {
      "email": this.currentUser.email
    };

    return this.http.post<any>(this.baseUrl + 'person/sendConfirmation.php', body, {headers : header});
  }

  confirmAccount(token: string) {
    let header = new HttpHeaders({'content-type': 'application/json'});

    let body = {
      "token": token,
      "action":"1"
    };

    return this.http.post<any>(this.baseUrl + 'person/auth.php', body, {headers : header});
  }

  deleteUser(personId: number) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

    let body = { 
      "id": personId,
      "active": "0"
    }

    console.log(body);

    return this.http.post<any>(this.baseUrl + 'person/setactive.php', body, options);
  }

  unDeleteUser(personId: number) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

    let body = { 
      "id": personId,
      "active": "1"
    }

    console.log(body);

    return this.http.post<any>(this.baseUrl + 'person/setactive.php', body, options);
  }

  showAlert(title: string, message: string, isError: boolean = false)
  {
    this.notifications = '<div class="alert ' + (isError ? 'alert-danger' : 'alert-success') +'" role="alert"><strong>' + title + '!</strong> ' + message + '</div>';
  }
}




// AuthGuard class for ensuring that users are logged in when using routing

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;
  constructor(private user: UserService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.user.isLoggedIn) {
      // redirect to some view explaining what happened
      this.router.navigateByUrl('/login');
      return false;
    } else {
      return true;
    }
  }
}

export class LoginResponse {
  status: string;
  id: number;
  token: string;
  message: string;
}