import { Component, OnInit } from '@angular/core';
import { SchoolService } from '../../../services/school.service';

@Component({
  selector: 'app-view-test-summary',
  templateUrl: './view-test-summary.component.html',
  styleUrls: ['./view-test-summary.component.css']
})
export class ViewTestSummaryComponent implements OnInit {

  public TestSummaryId = '';
  public testSummaryResult: TestSummaryResult;
  public breakdowns: Breakdown[] = [];
  public violations: Violation[] = [];
  public StartTime = '';
  public EndTime = '';

  public errorMessage = '';

  constructor(public school: SchoolService) { }

  ngOnInit() {
    this.loadTestSummary();
  }

  cancelUpdate() {
    this.school.viewTestSummaryPopup = false;
  }

  loadTestSummary() {
    if (this.school.TestSummaryId != '' && this.school.TestSummaryStudentId > 0) {

      this.TestSummaryId = this.school.TestSummaryId;
      
      this.school.getTestSummary(this.school.TestSummaryId, this.school.TestSummaryStudentId).subscribe(res => {
        console.log(res);
        this.testSummaryResult = res.results;
        this.breakdowns = res.breakdowns;
        this.violations = res.violations;

        console.log(this.testSummaryResult);
        console.log(this.breakdowns);
        console.log(this.violations);

        this.StartTime = new Date(this.testSummaryResult.start_time * 1000).toString();
        this.EndTime = new Date(this.testSummaryResult.end_time * 1000).toString();

        for (let i = 0; i < this.violations.length; i++) {
          this.violations[i].occuranceFormatted = new Date(this.violations[i].occurance * 1000).toLocaleString();
        }
      });
  
    }
  }
}



export class Breakdown {
  tbd_id: number;
  test_id: string;
  student_id: number;
  description: string;
  points: number;
  possible_points: number;
  results: string;
}

export class TestSummaryResult {
  test_id: string;
  student_id: number;
  start_time: number;
  end_time: number;
  lesson_id: number;
  score: number;
}

export class Violation {
  v_id: number;
  description: string;
  name: string;
  occurance: number;
  occuranceFormatted: string;

  public formatOccuranceValue(): void {
    this.occuranceFormatted = new Date(this.occurance * 1000).toString();
  }
}