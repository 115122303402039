import { Component, OnInit } from '@angular/core';
import { SchoolService } from 'src/app/services/school.service';
import { IdealClassroom } from 'src/app/model/class';

@Component({
  selector: 'app-manage-rooms',
  templateUrl: './manage-rooms.component.html',
  styleUrls: ['./manage-rooms.component.css']
})
export class ManageRoomsComponent implements OnInit {

  constructor(public school: SchoolService) { }

  ngOnInit() {
    this.school.updateRoomNumber = new IdealClassroom();
  }

  cancelUpdate() {
    this.school.viewManageRoomsPopup = false;
  }

  editRoom(room: IdealClassroom) {
    this.school.updateRoomNumber = room;
  }

  updateRoom() {
    this.school.addClassroom(this.school.updateRoomNumber.room_number, this.school.currentUserSchool.id, 0);
    this.school.updateRoomNumber = new IdealClassroom();
  }
}
