import { Component, OnInit } from '@angular/core';
import { SchoolService } from 'src/app/services/school.service';

@Component({
  selector: 'app-manage-school',
  templateUrl: './manage-school.component.html',
  styleUrls: ['./manage-school.component.css']
})
export class ManageSchoolComponent implements OnInit {

  public validationMessage = '';
  nameInvalid = false;
  public isScorm: boolean = true;

  constructor(public school: SchoolService) { }

  ngOnInit() {
    this.isScorm = this.school.selectedSchool.scorm == '1' ? true : false;
  }

  cancel() {
    this.school.viewManageSchoolPopup = false;
  }

  submitSchool() {
    this.school.selectedSchool.scorm = this.isScorm ? '1':'0';
    
    //remove https or http from domain

    if(this.school.doSchoolCreate)
    {
      if(this.doValidation())
      {
        console.log("Adding School");
        this.school.addSchool();
      }
    }
    else
    {
      if (this.doValidation()) {
        console.log("updating School");
        this.school.saveSelectedSchool();
        this.school.viewManageSchoolPopup = false;
      }
    }
    
  }

  doValidation() {
    let valid = true;

    if (this.school.selectedSchool.name == '') {
      valid = false;
      this.nameInvalid = true;
      
    }

    return valid;
  }
}
