import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import { SchoolService } from 'src/app/services/school.service';
import { IdealClass } from 'src/app/model/class';
import { Router } from '@angular/router';
import { IdealQuiz } from '../../../model/lesson';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.css']
})
export class ViewUserComponent implements OnInit {

  userTests: IdealQuiz[] = [];
  bestTest: IdealQuiz;
  latestTest: IdealQuiz;
  timeSpent: number = 0;
  lastLogin: string = '';

  constructor(public user: UserService, public school: SchoolService, public router: Router, private _location: Location) { }

  columnDefs = [
    {headerName: 'Type', field: 'test_type', sortable: true, filter: true, resizable: true, width: 70 },
    {headerName: 'Lesson Id', field: 'lessonId', sortable: true, filter: true, resizable: true, width: 90 },
    {headerName: 'Score', field: 'score', sortable: true, filter: true, resizable: true, width: 70  },
    {headerName: 'Violations', field: 'violations', sortable: true, filter: true, resizable: true, width: 90  },
    {headerName: 'Elapsed Time', field: 'elapsedTime', sortable: true, filter: true, resizable: true , width: 90 },
    {headerName: 'Date', field: 'date', sortable: true, filter: true, resizable: true },
];

  public rowData: any;

  ngOnInit() {
    console.log(this.school.viewUser);
    if (this.school.viewUser.role.id == 4) {
      this.school.getClassesByStudent(this.school.viewUser.id);
    }

    for (let q of this.school.allQuizzes) {
      console.log(q.studentId +":"+this.school.viewUser.id);
      if (q.studentId == this.school.viewUser.id) {
        this.userTests.push(q);
      }
    }
    console.log(this.school.allQuizzes);
    console.log(this.userTests);
    this.rowData = this.userTests;

    for (let t of this.userTests) {
      if (this.bestTest) {
        if (t.score > this.bestTest.score) {
          this.bestTest = t;
        }
      } else {
        this.bestTest = t;
      }

      if (this.latestTest) {
        if (t.startTime > this.latestTest.startTime) {
          this.latestTest = t;
        }
      } else {
        this.latestTest = t;
      }

      let e = t.elapsedTime.split(":");
      let min = parseInt(e[0]);
      let sec = parseInt(e[1]);
      this.timeSpent += (min * 60) + sec;

      // this.timeSpent += parseInt(t.elapsedTime);
    }

    if(this.school.viewUser.timestamped == null || this.school.viewUser.timestamped === 0)
    {
      this.lastLogin = "Never";
    }
    else
    {
      let d = new Date(this.school.viewUser.timestamped*1000);
      this.lastLogin = d.toLocaleDateString() +" at "+ d.toLocaleTimeString();
    }

    // console.log(this.bestTest);
    // console.log(this.latestTest);
    // console.log(this.timeSpent);

    // console.log(this.school.loadedClassesByStudent);
  }

  goToClass(cls: IdealClass, id: number) {
    // TODO: THis is a hack until the return values from the DB match the objects. The IdealClass object has id but the returned class objects from the API use 'class_id'
    console.log(cls);
    cls.id = id;
    this.school.selectedClass = cls;
    this.router.navigateByUrl('/view-class');
  }

  goBack()
  {
    this._location.back();
  }

  getFormattedDate(d) {
    let date = new Date(d);
    // let year = date.getFullYear();
    // let month = (1 + date.getMonth()).toString().padStart(2, '0');
    // let day = date.getDate().toString().padStart(2, '0');
    // let hour = date.getHours();
    // let pm = false;
    // if(hour > 12)
    // {
    //   pm = true;
    //   hour -= 12;
    // }
    // let minute = date.getMinutes();
    // return month + '/' + day + '/' + year + ' at ' + hour + ":" + minute+ (pm ? "PM":"AM") +" EST";
    // return month + '/' + day + '/' + year;
    return date.toLocaleDateString() + " at "+ date.toLocaleTimeString();
  }

  formatSeconds(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay; 
  }
}
