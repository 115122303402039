import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(public user: UserService, public route: Router) { }

  ngOnInit() {
    //this.user.testRead();
  }

  showManageAccounts() {
    this.route.navigateByUrl('/manage-accounts')
  }
}
