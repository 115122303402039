import { Injectable } from '@angular/core';
import { IdealSchool, SchoolUtil } from '../model/school';
import { IdealClass, IdealCourse, IdealClassroom } from '../model/class';
import { PersonUtil, Person } from '../model/Person';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Role } from '../model/role';
import { IdealLesson, LessonUtil, IdealQuiz, IdealQuizFlat } from '../model/lesson';

import * as faker from 'faker';
import { JsonPipe } from '@angular/common';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {

  // baseUrl = 'http://fnfgjidealindustriesapidev-env.hvbxczppmd.us-east-2.elasticbeanstalk.com/';
  devURL = '/api/';
  liveURL = 'https://api.ivrylearning.com/';
  baseUrl = 'https://api.ivrylearning.com/';
  //baseUrl = 'https://www.fnfsandbox.com/ideal-api/';

  // THese are for editing or creating new objects
  selectedUser: Person;
  selectedSchool: IdealSchool;
  selectedClass: IdealClass;
  selectedCourse: IdealCourse;
  selectedLesson: IdealLesson;

  public notifications: string = "";

  // The currently selected school for the current user (NOT the school being edited)
  currentUserSchool = new IdealSchool();

  // The class the current user is viewing (usually to add students to)
  public viewClass = new IdealClass();

  // The user currently being viewed through a dashboard or the view user page. Separate from selectedUser to keep any recently edited user from showing up on view user if no ID has been input...
  public viewUser: Person;

  viewManageSchoolPopup = false;
  doSchoolUpdate = false;
  doSchoolCreate = true;

  viewManageAccountPopup = false;
  manageAccountUserRole: Role;
  manageAccountSchoolId = 0;
  doUserCreate = true;
  doUserUpdate = false;
  public manageUserMessage = 'x';
  public manageUserType = 'x';

  viewManageRoomsPopup = false;
  updateRoomNumber: IdealClassroom;

  viewManageClassPopup = false;
  doClassUpdate = false;
  doClassCreate = true;

  viewManageCoursePopup = false;
  doCourseUpdate = false;
  doCourseCreate = true;

  viewManageLessonPopup = false;

  viewTestSummaryPopup = false;
  TestSummaryId = '';
  TestSummaryStudentId = 0;

  personUtil = new PersonUtil();
  schoolUtil = new SchoolUtil();

  loadedSchools: IdealSchool[] = [];

  loadedRooms: IdealClassroom[] = [];

  loadedClasses: IdealClass[] = [];
  loadedClassesByStudent: IdealClass[] = [];
  loadedClassesBySchool: IdealClass[] = [];

  loadedCourses: IdealCourse[] = [];
  selectCourseOnLoad : boolean = false;

  loadedStudentsByClass: Person[] = [];
  loadedStudentsByClass_Count: number;
  viewAddStudentToClassPopup = false;

  loadedLessonsByClass: IdealLesson[] = [];
  loadedLessonsByClass_Count: number;
  loadedLessonsByCourse: IdealLesson[] = [];
  loadedLessonsByCourse_Count: number;
  viewAddLessonToClassPopup = false;
  selectLessonOnLoad : boolean = false;

  allQuizzesFlat: IdealQuizFlat[] = [];
  allQuizzes: IdealQuiz[] = [];

  mockQuizzes_School: IdealQuiz[] = [];
  mockQuizzes_Student: IdealQuiz[] = [];

  loadedUsers: Person[] = [];
  loadedUsers_Inactive: Person[] = [];
  loadedSuperUsers: Person[] = [];
  loadedSchoolAdmins: Person[] = [];
  loadedInstructors: Person[] = [];
  loadedStudents: Person[] = [];

  // Used for searching
  allUsers: Person[] = [];
  filteredUsers: Person[] = [];
  searchExistingEmail = '';


  constructor(public http: HttpClient, public router: Router, public user: UserService, private main: MainService) {

    let url = window.location.href;
    console.log(url);
    if (url.includes('localhost')) {
      console.log('Setting to dev environment.')
      this.main.isDevelopment = true;
      this.baseUrl = this.devURL;
    }
    else
    {
      this.baseUrl = this.liveURL;
    }
    // TODO: Hard coding the school for now, but will eventually need to load schools the user is associated with and let them pick one...
    //this.getSchoolById(19);
  }

  editSchool(s: IdealSchool) {
    if (s != null) {
      this.selectedSchool = s;
      this.doSchoolUpdate = true;
      this.doSchoolCreate = false;
    }
    else {
      this.selectedSchool = new IdealSchool();
      this.doSchoolUpdate = false;
      this.doSchoolCreate = true;
    }

    this.viewManageSchoolPopup = true;
  }

  editClass(c: IdealClass) {
    if (c != null) {
      this.selectedClass = c;
      this.doClassUpdate = true;
      this.doClassCreate = false;
    }
    else {
      this.selectedClass = new IdealClass();
      this.doClassUpdate = false;
      this.doClassCreate = true;
    }
    console.log(c);
    this.viewManageClassPopup = true;
  }

  editCourse(c: IdealCourse) {
    if (c != null) {
      this.selectedCourse = c;
      this.doCourseUpdate = true;
      this.doCourseCreate = false;
    }
    else {
      this.selectedCourse = new IdealCourse();
      this.doCourseUpdate = false;
      this.doCourseCreate = true;
    }
    console.log(c);
    this.viewManageCoursePopup = true;
  }

  saveSchool() {
    console.log(this.selectedSchool);

    if (this.doSchoolCreate) {
      this.loadedSchools.push(this.selectedSchool);
      this.doSchoolCreate = false;
    }

    this.viewManageSchoolPopup = false;
  }

  addSchool() {
    let header = new HttpHeaders({ 'content-type': 'application/json' });

    console.log(this.selectedSchool);

    this.http.post<any>(this.baseUrl + 'school/add.php', this.selectedSchool, { headers: header }).subscribe(data => {
      
      console.log(data);
      let d = JSON.parse(JSON.stringify(data));

      let schoolID = d.school.s_id;

      if (data.status == "success") {

        this.updateUserRoleAndSchool(this.user.currentUser.id, 1, d.school.s_id).subscribe(data => {

          console.log(data);

          this.getSchoolsByUser(this.user.currentUser.id).subscribe((data) => {
            this.loadedSchools = data.schools;
            //check if school is a scorm school, if it is then create the scorm class
            if(this.selectedSchool.scorm == '1')
            {
              this.createScormClass(schoolID);
            }
            else
            {
              this.router.navigateByUrl('school-list');
              this.viewManageSchoolPopup = false;
            }
          });

        });
      }
    })
  }

  saveSelectedSchool() {
    let header = new HttpHeaders({ 'content-type': 'application/json' });

    console.log("Updating school with data: "+JSON.stringify(this.selectedSchool));

    this.http.post<any>(this.baseUrl + 'school/upd.php', this.selectedSchool, { headers: header }).subscribe(data => {
      
      console.log(data);
      // let d = JSON.parse(JSON.stringify(data));

      // console.log("name: "+d.school.school_name);

      if (data.status == "success") {

        this.getSchoolsByUser(this.user.currentUser.id).subscribe((data) => {
            this.loadedSchools = data.schools;
            this.router.navigateByUrl('school-list');
          });
      }

    })
  }

  saveSelectedCourse() {
    let header = new HttpHeaders({ 'content-type': 'application/json' });

    console.log("Updating course with data: "+JSON.stringify(this.selectedCourse));

    this.http.post<any>(this.baseUrl + 'course/upd.php', this.selectedCourse, { headers: header }).subscribe(data => {
      
      console.log(data);

      if (data.status == "success") {
        this.loadAllCourses();
      }
    })
  }

  saveSelectedLesson() {
    let header = new HttpHeaders({ 'content-type': 'application/json' });

    console.log("Updating lesson with data: "+JSON.stringify(this.selectedLesson));

    this.http.post<any>(this.baseUrl + 'lesson/upd.php', this.selectedLesson, { headers: header }).subscribe(data => {
      
      console.log(data);

      // this.loadedLessonsByCourse = [];

      if (data.status == "success") {
        this.getLessonsByCourse(this.selectedCourse.id);
      }
    })
  }

  getSchoolById(id: number) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

    let body = {
      "searchby": "id",
      "id": id,
      "name": ""
    }

    return this.http.post<any>(this.baseUrl + 'school/inq.php', body, options)
  }

  getAllSchools() {
    this.http.get<any>(this.baseUrl + 'school/list.php').subscribe(data => {
      console.log(data);
    })
  }

  getSchoolActiveSeats(schoolId: number) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    let body = {
      "id": schoolId
    }

    return this.http.post<any>(this.baseUrl + 'school/seats.php', body, options);
  }

  getSchoolsByUser(personId: number) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    let body = {
      "person_id": personId,
      "school_id": "",
      "role_id": ""
    }

    return this.http.post<any>(this.baseUrl + 'userrole/list.php', body, options);
  }

  selectSchool(school: IdealSchool) {

    let schoolId = school.id;
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

    let body = {
      "person_id": this.user.currentUser.id,
      "school_id": schoolId
    }

    this.getSchoolById(schoolId).subscribe(data => {

      // console.log(data.schools[0]);
      this.currentUserSchool = data.schools[0];
      this.selectedSchool = this.currentUserSchool;
      // this.loadUsersBySchool(this.currentUserSchool.id);
      this.loadClassroomsBySchool();
      
      if(this.user.currentUser.isSuperUser)
      {
        this.user.currentUser.role = this.setUserRole(1);
        this.router.navigateByUrl('/school-dashboard');

        // if(localStorage.getItem("currentURL") != "")
        //   {
        //     console.log(this.currentUserSchool.id);
        //     this.router.navigateByUrl(localStorage.getItem("currentURL"));
        //   }
        //   else
        //   {
        //     console.log("GOING TO DASHBOARD");
        //     this.router.navigateByUrl('/school-dashboard');
        //   }
      }
      else
      {
        this.http.post<any>(this.baseUrl + 'userrole/list.php', body, options).subscribe(data => {
          // console.log("DATA:"+JSON.stringify(data));
          let roleId = data[0].role;
          this.user.currentUser.role = this.setUserRole(roleId);
          this.selectedSchool = this.currentUserSchool;
          localStorage.setItem("currentUser", JSON.stringify(this.user.currentUser));
          this.router.navigateByUrl('/school-dashboard');
          // if(localStorage.getItem("currentURL") != "")
          //   this.router.navigateByUrl(localStorage.getItem("currentURL"));
          // else
          // {
          //   console.log("GOING TO DASHBOARD");
            
          // }
        });
      }

    });
    
  }

  viewSchool(s: IdealSchool) {
    this.selectedSchool = s;
    this.router.navigateByUrl('/class-list')
  }

  updateClassroom() {
    //TODO: This method doesn't work quite yet. Need to flesh it out.
    let header = new HttpHeaders({ 'content-type': 'application/json' });

    let room: IdealClassroom = new IdealClassroom();
    room.room_number = String(this.selectedClass.classroom_id);
    room.school_id = this.currentUserSchool.id;
    room.seats = 0;

    this.http.post<any>(this.baseUrl + 'classroom/add.php', room, { headers: header }).subscribe(data => {
      console.log(data);
      this.loadClassroomsBySchool();
      //this.selectedClass.classroom_id = data.classroom.classrooms[0].id;
    });
  }

  addClassroom(room: string, schoolId: number, seats: number) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });

    let body = {
      "room": room,
      "school_id": schoolId,
      "seats": seats
    }

    this.http.post<any>(this.baseUrl + 'classroom/add.php', body, { headers: header }).subscribe(data => {
      console.log(data);
      this.loadClassroomsBySchool();
    });
  }

  addSelectedCourse() {
    let header = new HttpHeaders({ 'content-type': 'application/json' });

    let course: IdealCourse = this.selectedCourse;

    let body = {
      "name": course.name,
      "description": course.description,
      "active": course.active
    }

    console.log("Sending course data: " + JSON.stringify(body));

    this.http.post<any>(this.baseUrl + 'course/add.php', body, { headers: header }).subscribe(data => {
      console.log(data);
      this.selectCourseOnLoad = true;
      this.loadAllCourses();
    });
  }

  addSelectedLesson() {
    let header = new HttpHeaders({ 'content-type': 'application/json' });

    let lesson: IdealLesson = this.selectedLesson;
    lesson.course_id = this.selectedCourse.id;

    let body = {
      "sequence_num": lesson.sequence_number,
      "title": lesson.title,
      "description": lesson.description,
      "course_id": lesson.course_id,
      "picture_url": lesson.picture_url
    }

    console.log("Sending lesson data: " + JSON.stringify(body));

    this.http.post<any>(this.baseUrl + 'lesson/add.php', body, { headers: header }).subscribe(data => {
      console.log(data);
      // this.selectLessonOnLoad = true;
      this.getLessonsByCourse(lesson.course_id);
    });
  }

  loadClassroomsBySchool() {
    let header = new HttpHeaders({ 'content-type': 'application/json' });

    let body = {
      "SchoolID": this.currentUserSchool.id
    }

    this.http.post<any>(this.baseUrl + 'classroom/list.php', body, { headers: header }).subscribe(data => {
      //console.log(data);
      this.loadedRooms = data;
    });
  }

  loadAllCourses() {
    let header = new HttpHeaders({ 'content-type': 'application/json' });

    this.http.get<any>(this.baseUrl + 'course/list.php', { headers: header }).subscribe(data => {

      this.loadedCourses = data.courses;
      console.log(this.loadedCourses);

      if(this.selectCourseOnLoad)
      {
        this.selectedCourse = this.loadedCourses[this.loadedCourses.length-1];
        this.selectedLesson = new IdealLesson();
        this.loadedLessonsByCourse = [];
        this.selectCourseOnLoad = false;
        console.log("New Selected Course: "+ JSON.stringify(this.selectedCourse));
      }
        
    });
  }

  // loadLessons(course_id: IdealCourse) {
  //   let header = new HttpHeaders({ 'content-type': 'application/json' });

  //   this.http.get<any>(this.baseUrl + 'lesson/list.php', { headers: header }).subscribe(data => {
  //     console.log(data);
  //     this.loadedCourses = data.courses;
  //     console.log(this.loadedCourses);
  //   });
  // }

  saveSelectedClass() {
    // Check the ID field to determine if this is an add or an update
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    let cls: IdealClass = this.selectedClass;
    console.log("Saving class info: "+ JSON.stringify(cls));

    if (cls.id) { // Updating an existing class
      let body = {
        "id": cls.id,
        "course_id": cls.course_id,
        "class_name": cls.name,
        "room_id": cls.classroom_id,
        "instructor_id": cls.instructor_id,
        "start_date": cls.start_date,
        "end_date": cls.end_date,
        "start_time": cls.start_time,
        "mon": cls.monday ? "1" : "0",
        "tue": cls.tuesday ? "1" : "0",
        "wed": cls.wednesday ? "1" : "0",
        "thu": cls.thursday ? "1" : "0",
        "fri": cls.friday ? "1" : "0",
      }

      console.log("Updating existing class: "+JSON.stringify(body));

      this.http.post<any>(this.baseUrl + 'class/upd.php', body, { headers: header }).subscribe(data => {
        console.log(data);
        this.getClassesBySchoolId(this.currentUserSchool.id);
      })
    }
    else // Creating a new class
    {
      cls.school_id = this.currentUserSchool.id;

      // Have to do this as a temp fix until the days are consistent.
      let cls2: any;
      cls2 = cls;
      cls2.mon = cls.monday;
      cls2.tue = cls.tuesday;
      cls2.wed = cls.wednesday;
      cls2.thu = cls.thursday;
      cls2.fri = cls.friday;
      console.log("Creating new class:");
      console.log(cls2);

      this.http.post<any>(this.baseUrl + 'class/add.php', cls2, { headers: header }).subscribe(data => {
        console.log(data);
        this.selectedClass.id = data.id;
        this.getClassesBySchoolId(this.currentUserSchool.id);
      });
    }

    this.viewManageClassPopup = false;
  }

  createScormClass(schoolID) {
    //Create new room
    let header = new HttpHeaders({ 'content-type': 'application/json' });

    let body = {
      "room": "SCORM ROOM",
      "school_id": schoolID,
      "seats": 0
    }

    console.log(body);

    this.http.post<any>(this.baseUrl + 'classroom/add.php', body, { headers: header }).subscribe(data => {
      console.log(data);

      //Create new class
      let header = new HttpHeaders({ 'content-type': 'application/json' });

      let cls = new IdealClass();
      cls.name = "SCORM";
      cls.course_id = 1;
      cls.classroom_id = data.classroom.cl_id;
      cls.room = "SCORM ROOM";
      cls.school_id = schoolID;
      cls.start_date = "2021-04-22";
      cls.end_date = "2021-08-22";
      cls.start_time = "1:00 PM";
      cls.end_time = "1:45 PM";
      cls.active = "Y";
      console.log("Creating new class:");
      console.log(cls);

      this.http.post<any>(this.baseUrl + 'class/add.php', cls, { headers: header }).subscribe((data) => {
        console.log(data);
          this.router.navigateByUrl('school-list');
          this.viewManageSchoolPopup = false;
      });
      
    });
    
  }

  enrollStudent(studentId: number, classId: number) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });

    let body = {
      "student_id": studentId,
      "class_id": classId
    }

    console.log("Enroll: "+ JSON.stringify(body));

    this.http.post<any>(this.baseUrl + 'enrollment/add.php', body, { headers: header }).subscribe(data => {
      console.log(data);
      this.getStudentsByClass(this.selectedClass.id);
    });
  }

  unenrollStudent(studentId: number, classId: number) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });

    let body = {
      "student_id": studentId,
      "class_id": classId
    }

    console.log("Unenroll: "+ JSON.stringify(body));

    this.http.post<any>(this.baseUrl + 'enrollment/remove.php', body, { headers: header }).subscribe(data => {
      console.log(data);
      this.getStudentsByClass(this.selectedClass.id);
    });
  }

  getLessonsByStudent(studentId: number) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });

    let body = {
      "student_id": studentId
    }

    this.http.post(this.baseUrl + 'lesson/status.php', body, { headers: header}).subscribe(data => {
      console.log(data);
    });
  }


  updateUserRole(personId: number, roleId: number, schoolId: number) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

    // Role Id is the NEW role. The assumption here is that there's only one role per person per school.
    let body = {
      "role_id": roleId,
      "person_id": personId,
      "school_id": schoolId
    }

    return this.http.post<any>(this.baseUrl + 'userrole/update.php', body, options);
  }


  getLessonsByClass(classId: number) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });

    let body = {
      "searchby": "class",
      "class_id": classId
    }

    return this.http.post<any>(this.baseUrl + 'lesson/list.php', body, { headers: header});
  }

  getLessonsByCourse(courseID: number) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });

    let body = {
      "searchby": "course",
      "course_id": courseID
    }

    this.http.post<any>(this.baseUrl + 'lesson/list.php', body, { headers: header}).subscribe(data => {

      console.log(data);

      if(data.status == "Failed")
      {
        this.loadedLessonsByCourse = [];
        return;
      }
 
      this.loadedLessonsByCourse = data.lessons;

      if(this.selectLessonOnLoad)
      {
        this.selectedLesson = this.loadedLessonsByCourse[this.loadedLessonsByCourse.length-1];
        this.selectLessonOnLoad = false;
      }
    });
  }

  getTestSummary(testId: string, studentId: number) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });

    let body = {
      "test_id": testId,
      "student_id": studentId,
    }

    return this.http.post<any>(this.baseUrl + 'test/summary.php', body, { headers: header})
  }


  addSelectedUser(roleId: number, schoolId: number) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });

    console.log("Creating the following user: ");
    

    var body =
    {
      user: this.selectedUser,
      schoolId: schoolId,
      roleId: roleId
    }

    console.log(body);

    return this.http.post<any>(this.baseUrl + 'person/add.php', body, { headers: header });
  }

  // For updating users on a school's accounts page
  updateSelectedUser() {
    let header = new HttpHeaders({ 'content-type': 'application/json' });

    return this.http.post<any>(this.baseUrl + 'person/upd.php', this.selectedUser, { headers: header })
  }


  addUserToListGroup(user: Person, roleId: number) {
    if (roleId == 1) {
      this.loadedSuperUsers.push(user);
    } else if (roleId == 2) {
      this.loadedSchoolAdmins.push(user);
    } else if (roleId == 3) {
      this.loadedInstructors.push(user);
    } else if (roleId == 4) {
      this.loadedStudents.push(user);
    }
  }


  loadUserById(id: number) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

    let body = {
      "searchby": "id",
      "id": id,
      "email": ""
    }

    this.http.post<any>(this.baseUrl + 'person/inq.php', body, options).subscribe(data => {
      console.log(data);
      this.viewUser = data.people[0];
      console.log(this.viewUser);
    })
  }


  getRoleByUserId(userId: number): number {
    let roleId = 0;
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    let body = {
      "person_id": userId,
      "school_id": "19"
    }

    this.http.post<any>(this.baseUrl + 'userrole/list.php', body, options).subscribe(data => {
      console.log(data);

    })

    return roleId;
  }

  filterUserlist() {
    if (this.searchExistingEmail.length > 0) {
      this.filteredUsers = this.loadedUsers.filter(u => u.email.toLowerCase().includes(this.searchExistingEmail.toLowerCase()));
    }
    else
    {
      this.filteredUsers = [];
    }
  }

  updateUserRoleAndSchool(personId: number, roleId: number, schoolId: number) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

    let body = {
      "person_id": personId,
      "role_id": roleId,
      "school_id": schoolId
    }

    console.log("BODY: "+ JSON.stringify(body));

    return this.http.post(this.baseUrl + 'userrole/add.php', body, options);
  }

  getLoadedStudent(personId: number): Person {
    return this.loadedStudents.find(s => s.id == personId);
  }

  loadUsersBySchool(schoolId: number) {
    // console.log("Loading users by school");
    this.clearCurrentUserList();
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

    let body = {
      "school_id": schoolId
    }

    this.loadedUsers = [];

    this.http.post<any>(this.baseUrl + 'userrole/list.php', body, options).subscribe(data => {

      // console.log(data);
      
      if (!data || !data.people || data.status == "Failed")
        return;

      for (let u of data.people) {
        this.loadedUsers.push(u);

        if (u.active == "inactive") {
          u.role = this.setUserRole(u.role_id);
          this.loadedUsers_Inactive.push(u);
        } else {
          if (u.role_id == 1) {
            u.role = this.setUserRole(1);
            this.loadedSuperUsers.push(u);
          } else if (u.role_id == 2) {
            u.role = this.setUserRole(2);
            this.loadedSchoolAdmins.push(u);
          } else if (u.role_id == 3) {
            u.role = this.setUserRole(3);
            this.loadedInstructors.push(u);
          } else if (u.role_id == 4) {
            u.role = this.setUserRole(4);
            this.loadedStudents.push(u);
          }
        }

      }
      // console.log(this.loadedStudents);
    })
  }


  setUserRole(roleId: number) {
    if (roleId == 1) {
      return { id: 1, name: "Super User" }
    } else if (roleId == 2) {
      return { id: 2, name: "School Admin" }
    } else if (roleId == 3) {
      return { id: 3, name: "Instructor" }
    } else if (roleId == 4) {
      return { id: 4, name: "Student" }
    }
    return null;
  }


  getClassesBySchoolId(id: number) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

    let body = {
      "searchby": "school",
      "school_id": this.currentUserSchool.id,
      "instructor_id": ""
    }

    //console.log(this.currentUserSchool.id);
    this.currentUserSchool.classes = [];

    this.http.post<any>(this.baseUrl + 'class/inq.php', body, options).subscribe(data => {
      console.log(data);
      this.currentUserSchool.classes = data.classes;
      this.getActiveSeats();
    });
  }

  getActiveSeats() {
    this.currentUserSchool.active_seats = 0;

    if(this.currentUserSchool.classes != null)
    {
      for (let c of this.currentUserSchool.classes) {
        this.currentUserSchool.active_seats += c.enrolled;
      }
    }
  }

  getClassesByStudent(personId: number) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

    let body = {
      "searchby": "student",
      "student_id": personId,
      "class_id": ""
    }

    this.currentUserSchool.classes = [];

    this.http.post<any>(this.baseUrl + 'enrollment/inq.php', body, options).subscribe(data => {
      this.loadedClassesByStudent = data.classes;
      // console.log("Loaded Classes for student: "+ JSON.stringify(data));
    });
  }

  getStudentsByClass(classId: number) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

    let body = {
      "searchby": "class",
      "student_id": "",
      "class_id": classId
    }

    //console.log(this.currentUserSchool.id);
    //this.currentUserSchool.classes = [];

    this.http.post<any>(this.baseUrl + 'enrollment/inq.php', body, options).subscribe(data => {
      console.log(data);
      this.loadedStudentsByClass = data.students;
      this.loadedStudentsByClass_Count = data.count;

      this.loadedLessonsByClass = new LessonUtil().createMockLessons(classId);
      //console.log(this.loadedLessonsByClass);
    });
  }

  findUserById(id: number) {
    return this.loadedUsers.find(u => u.id == id);
  }


  // Returns an error if there are any scheduled classes. Those need to be removed/deleted first.
  deleteSchool(schoolId: number) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    
    let body = {
      "school_id": schoolId
    }

    return this.http.post<any>(this.baseUrl + 'school/rem.php', body, options);
  }

  // Returns an error if there are any active enrollments. Those need to be removed/deleted first.
  deleteClass(classId: number) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    
    let body = {
      "class_id": classId
    }

    return this.http.post<any>(this.baseUrl + 'class/rem.php', body, options);
  }

  // Removes all students from a class. Comments are optional
  deleteEnrollmentsByClass(classId: number, comments: string) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    
    let body = {
      "class_id": classId,
      "comments": comments
    }

    return this.http.post<any>(this.baseUrl + 'enrollment/cancelAll.php', body, options);
  }

  // Removes a student from a class. Comments are optional
  deleteEnrollmentByUserAndClass(classId: number, studentId: number, comments: string) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    
    let body = {
      "class_id": classId,
      "student_id": studentId,
      "comments": comments
    }

    return this.http.post<any>(this.baseUrl + 'enrollment/cancel.php', body, options);
  }

  deleteCourse(courseID: number) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    
    let body = {
      "course_id": courseID
    }

    return this.http.post<any>(this.baseUrl + 'course/delete.php', body, options).subscribe((data) => {

      console.log("Deleting course response: "+ JSON.stringify(data));
      
      if(data.status == "Success")
      {
        this.selectedCourse = new IdealCourse();
        this.loadAllCourses();
      }

      this.showAlert(data.status, data.message, (data.status == "Failed" ? true : false));
    });
  }

  deleteLesson(lessonID: number) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    
    let body = {
      "lesson_id": lessonID
    }

    return this.http.post<any>(this.baseUrl + 'lesson/delete.php', body, options).subscribe((data) => {

      console.log("Deleting lesson response: "+ JSON.stringify(data));
      
      if(data.status == "Success")
      {
        this.selectedLesson = new IdealLesson();
        this.getLessonsByCourse(this.selectedCourse.id);
      }

      this.showAlert(data.status, data.message, (data.status == "Failed" ? true : false));
    });
  }

  deleteRoom(roomID: number) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    
    let body = {
      "room_id": roomID
    }

    return this.http.post<any>(this.baseUrl + 'classroom/rem.php', body, options).subscribe((data) => {

      console.log("Deleting classroom response: "+ JSON.stringify(data));
      
      if(data.status == "Success")
      {
        this.loadClassroomsBySchool();
      }

      this.showAlert(data.status, data.message, (data.status == "Failed" ? true : false));
    });
  }

  removeAllStudents() {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    
    let body = {
      "school_id": this.selectedSchool.id
    }

    return this.http.post<any>(this.baseUrl + 'person/delete.php', body, options).subscribe((data) => {

      console.log("Removing All Students response: "+ JSON.stringify(data));
      
      if(data.status == "Success")
      {
        this.loadUsersBySchool(this.selectedSchool.id);
      }
      else
        console.log("FAILED TO REMOVE STUDENTS");

      this.showAlert(data.status, data.message, (data.status == "Failed" ? true : false));
    });
  }

  getAllQuizzes(pSchool_id: number = null) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

    if (pSchool_id)
      return this.http.post<any>(this.baseUrl + 'test/list.php', {school_id: pSchool_id}, options);
    else
      return this.http.post<any>(this.baseUrl + 'test/list.php', options);
    //.subscribe(data => {
    //   console.log(JSON.stringify(data));  // Return the JSON object as a string, so it can be parsed online and mapped to an object
    //   console.log(JSON.parse(JSON.stringify(data)));
    //   //console.log(data.courses[1]);

    //   for (let c of data.courses) {
    //     //console.log(c);
    //     if (c) {
    //       for (let l of c.lessons) {
    //         //console.log(l)
    //         if (l) {
    //           for (let t of l.test_attempts) {
    //             //console.log(t);
    //             if (t) {
    //               let q = new IdealQuizFlat();
    //               q.course_id = c.course_id;
    //               q.lesson_id = l.lesson_id;
    //               q.test_id = t.test_id;
    //               q.student_id = t.student_id;
    //               q.student_name = t.student_name;
    //               q.start_time = new Date(t.start_time * 1000);
    //               q.end_time = new Date(t.end_time * 1000);
    //               q.elapsed_time = t.elapsed_time;
    //               q.score = t.score;
    //               q.violations = 0;
  
    //               this.allQuizzesFlat.push(q);
    //               this.allQuizzes.push(q.convertToIdealQuiz());
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }

      //console.log(this.allQuizzes);
    //});
  }

  getAllQuizzesByClass(classId: number) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    
    let body = {
      "class_id": classId
    }

    return this.http.post<any>(this.baseUrl + 'test/class.php', body, options);
  }

  getAllQuizzesByStudent(studentID: number) 
  {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    
    let body = {
      "student_id": studentID
    }

    return this.http.post<any>(this.baseUrl + 'test/inq.php', body, options);
  }

  loadMockQuizzesForSchool() {
    for (let i = 0; i < this.loadedStudents.length; i++) {
      let student = this.loadedStudents[i];
      let lessonId = faker.random.number(5) + 1;
      let quizzes = new LessonUtil().createMockQuizzes(student, lessonId);

      for (let q of quizzes) {
        this.mockQuizzes_School.push(q);
      }
    }
  }

  loadMockQuizzesForStudent(student: Person) {
    let lessonId = faker.random.number(5) + 1;
    let quizzes = new LessonUtil().createMockQuizzes(student, lessonId);

    for (let q of quizzes) {
      this.mockQuizzes_Student.push(q);
    }
  }

  clearCurrentUserList() {
    this.loadedUsers = [];
    this.loadedUsers_Inactive = [];
    this.loadedInstructors = [];
    this.loadedSchoolAdmins = [];
    this.loadedStudents = [];
    this.loadedSuperUsers = [];
  }

  editUser(u: Person) {
    console.log(u);
    this.selectedUser = u;
    this.doUserUpdate = true;
    this.doUserCreate = false;
    this.manageUserType = u.role.name;
    this.viewManageAccountPopup = true;
  }


  createSchoolAdmin() {
    this.manageAccountUserRole = { id: 2, name: "School Admin" };
    this.doUserUpdate = false;
    this.doUserCreate = true;
    this.manageUserType = "SCHOOL ADMIN"
    this.manageUserMessage = "This user will have permission to add/remove instructors, students, and class for a given school."
    this.selectedUser = new Person();
    this.viewManageAccountPopup = true;
  }

  createInstructor() {
    this.manageAccountUserRole = { id: 3, name: "Instructor" };
    this.doUserUpdate = false;
    this.doUserCreate = true;
    this.manageUserType = "INSTRUCTOR"
    this.manageUserMessage = "You are creating a new INSTRUCTOR. This user will have permission to view and manage students for any classes they teach."
    this.selectedUser = new Person();
    this.viewManageAccountPopup = true;
  }

  createStudent() {
    this.manageAccountUserRole = { id: 4, name: "Student" };
    this.doUserUpdate = false;
    this.doUserCreate = true;
    this.manageUserType = "STUDENT"
    this.manageUserMessage = "You are creating a new STUDENT. This user will have permission to view their class data for any enrolled classes."
    this.selectedUser = new Person();
    this.viewManageAccountPopup = true;
  }


  loadAllUsers() {
    this.allUsers = [];

    this.http.get<any>(this.baseUrl + 'person/list.php').subscribe(data => {
      this.allUsers = data.people;
    })
  }

  // listUsersByRole(roleId: number) {
  //   this.loadedInstructors = [];
  //   this.loadedSchoolAdmins = [];
  //   this.loadedStudents = [];
  //   this.loadedSuperUsers = [];

  //   let body = { 
  //     "role_id": roleId
  //   }

  //   this.http.post<any>(this.baseUrl + 'userrole/list.php', body).subscribe(data => {
  //     console.log(data);
  //     if (roleId == 1) {
  //       this.loadedSuperUsers = data.people;
  //     } else if (roleId == 2) {
  //       this.loadedSchoolAdmins = data.people;
  //     } else if (roleId == 3) {
  //       this.loadedInstructors = data.people;
  //     } else if (roleId == 4) { 
  //       this.loadedStudents = data.people;
  //     }
  //   })
  // }


  createMockSchool() {
    this.selectedSchool = new SchoolUtil().getMockSchool();
  }

  showAlert(title: string, message: string, isError: boolean = false)
  {
    this.notifications = '<div class="alert ' + (isError ? 'alert-danger' : 'alert-success') +'" role="alert"><strong>' + title + '!</strong> ' + message + '</div>';
  }

  clearAlert()
  {
    this.notifications = "";
  }

  getElapsedTimeInMinutes(seconds: any) : any {
    if (seconds == null) return '00:00';
    if (seconds == 0) return '00:00';

    try
    {
      var minutes = Math.floor(seconds / 60);
      var secondsRemaining = seconds % 60;
      return `${minutes.toString()}:${secondsRemaining < 10 ? "0" + secondsRemaining : secondsRemaining}`;
    }
    catch
    {
      console.log("[SchoolDashboardComponent] Unable to format time: " + seconds);
      return '00:00';
    }
    
  }

  removeDomain(username: string)
  {
    if(username.includes("||"))
    {
      let u = username.split("||");
      return u[0];
    }
    else
      return username;
  }

}
