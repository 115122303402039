import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/account/login/login.component';
import { DashboardComponent } from './components/core/dashboard/dashboard.component';
import { UserAccountComponent } from './components/account/user-account/user-account.component';
import { AuthGuard } from './services/user.service';
import { LogoutComponent } from './components/account/logout/logout.component';
import { ManageAccountsComponent } from './components/core/manage-accounts/manage-accounts.component';
import { ClassOverviewComponent } from './components/core/class-overview/class-overview.component';
import { SchoolListComponent } from './components/core/school-list/school-list.component';
import { ClassListComponent } from './components/core/class-list/class-list.component';
import { ViewUserComponent } from './components/core/view-user/view-user.component';
import { ViewClassComponent } from './components/core/view-class/view-class.component';
import { HelpPageComponent } from './components/core/help-page/help-page.component';
import { SchoolDashboardComponent } from './components/core/school-dashboard/school-dashboard.component';
import { LoginHelpComponent } from './components/core/login-help/login-help.component';
import { RouterHistoryService } from './services/router-history.service';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'quick_guide', redirectTo: '../assets/QUICK_GUIDE.pdf'},
  { path: 'login-help', component: LoginHelpComponent },
  { path: 'account', component: UserAccountComponent, canActivate: [AuthGuard] },
  { path: 'manage-accounts', component: ManageAccountsComponent, canActivate: [AuthGuard] },
  { path: 'school-list', component: SchoolListComponent, canActivate: [AuthGuard] },
  { path: 'class-overview', component: ClassOverviewComponent, canActivate: [AuthGuard] },
  { path: 'class-list', component: ClassListComponent, canActivate: [AuthGuard] },
  { path: 'view-user', component: ViewUserComponent, canActivate: [AuthGuard] },
  { path: 'view-class', component: ViewClassComponent, canActivate: [AuthGuard] },
  { path: 'help-page', component: HelpPageComponent, canActivate: [AuthGuard] },
  { path: 'school-dashboard', component: SchoolDashboardComponent, canActivate: [AuthGuard] },
  { path: 'main', component: DashboardComponent, canActivate: [AuthGuard] }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [RouterHistoryService]
})
export class AppRoutingModule { }
