import { Component, OnInit } from '@angular/core';
import { SchoolService } from 'src/app/services/school.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-manage-lesson',
  templateUrl: './manage-lesson.component.html',
  styleUrls: ['./manage-lesson.component.css']
})
export class ManageLessonComponent implements OnInit {

  constructor(public school: SchoolService, public user: UserService) { }

  ngOnInit() {
  }

  cancelUpdate() {
    this.school.viewManageLessonPopup = false;
  }

  updateLesson() {
    
  }
}
