import { Component, OnInit } from '@angular/core';
import { SchoolService } from 'src/app/services/school.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  constructor(public user: UserService, public school: SchoolService) { }

  ngOnInit() {
  }

  hover(state: boolean)
  {
    if(state)
    {
      localStorage.setItem("clearURL", "true");
    }
    else
    {
      localStorage.setItem("clearURL", "false");
    }
  }

}
