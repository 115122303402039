import { Role } from './role';
import * as faker from 'faker';

export class Person {
    
    constructor() {
        this.id = 1;
        this.username = '';
        this.password = '';
        this.password1 = '';
        this.password2 = '';
        this.firstname = '';
        this.lastname = '';
        this.email = '';
        this.phone1 = '';
        this.phone2 = '';
        this.confirmed = '0';
        this.isSuperUser = false;
        this.role = new Role(4, "Student");
        this.timestamped = 0;
    }

    id: number;
    username: string;
    firstname: string;
    lastname: string;
    password: string;
    password1: string;
    password2: string;
    email: string;
    phone1: string;
    phone2: string;
    role_id: number;
    role: Role;
    timestamped: number;
    confirmed: string;

    isSuperUser: boolean;
}

export class PersonUtil {

	public getMockUser(roleId: number) {
		let p: Person = new Person();
        p.email = faker.internet.email();
        p.phone1 = faker.phone.phoneNumber();
        p.phone2 = faker.phone.phoneNumber();
		p.firstname = faker.name.firstName();
        p.lastname = faker.name.lastName();
        p.username = 'user' + faker.random.number(9999);
        p.password = 'password1';
		//p.id = faker.random.number(500);
        //p.role = this.getMockRole(faker.random.number({min: 1, max: 4}));
        p.role = this.getMockRole(roleId);
        p.role_id = roleId;
		return p;
	}
	
	public getMockRole(id: number) {
    if (id == 1) { return new Role(1, "Super User"); }
    if (id == 2) { return new Role(2, "School Admin"); }
    if (id == 3) { return new Role(3, "Instructor"); }
    if (id == 4) { return new Role(4, "Student"); }
  }
}