import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Person } from 'src/app/model/Person';
import { Router } from '@angular/router';
import { SchoolService } from 'src/app/services/school.service';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.css']
})
export class UserAccountComponent implements OnInit {

  public userDetails: Person;
  public confirmationEmailSent: boolean = false;
  public notificationForPassword: boolean = false;
 
  constructor(public user: UserService, public router: Router, public school: SchoolService) { }

  ngOnInit() {
    this.userDetails = this.user.currentUser;
  }

  updateAccount() {
    this.user.updateCurrentUser().subscribe((data) => {
      console.log(data);
      // TODO: Show a message confirming the update.
      if(data.status == "Success")
      {
        setTimeout(()=>{
          this.user.notifications = "";
        }, 2000);
      }
      this.user.showAlert(data.status, data.message, (data.status == "Failed" ? true : false));
    });
  }

  cancelUpdate() {
    this.router.navigateByUrl('school-dashboard');
  }

  updatePassword(currentPassword, newPassword, confirmPassword) {

    this.user.notifications = "";
    this.notificationForPassword = true;

    if(newPassword != confirmPassword)
    {
      this.user.showAlert("Failed", "Make sure your new passwords match.", true);
      return;
    }

    if(newPassword.length < 8)
    {
      this.user.showAlert("Failed", "Make sure your new password is at least 8 characters.", true);
      return;
    }

    this.user.updatePassword(currentPassword, newPassword).subscribe((data)=>{
      console.log(data);
      if(data.status == "Success")
      {
        setTimeout(()=>{
          this.user.notifications = "";
        }, 2000);
      }
      this.user.showAlert(data.status, data.message, (data.status == "Failed" ? true : false));
    });
  }

  sendConfirmationEmail()
  {
    this.notificationForPassword = false;
    this.user.sendConfirmationEmail().subscribe((data)=>{
      console.log(data);
      if(data.status == "Success")
      {
        this.confirmationEmailSent = true;
      }
      this.user.showAlert(data.status, data.message, (data.status == "Failed" ? true : false));
    });
  }

  confirmToken(token)
  {
    this.notificationForPassword = false;
    this.user.notifications = "";

    this.user.confirmAccount(token).subscribe((data)=>{
      console.log(data);
      if(data.status == "Success")
      {
        this.userDetails.confirmed = '1';
        setTimeout(()=>{
          this.user.notifications = "";
        }, 2000)
      }
      this.user.showAlert(data.status, data.message, (data.status == "Failed" ? true : false));
    });
  }
}
